import { Controller } from 'stimulus';
import { debounce } from 'lodash';

import { handleAJAXError } from '@ftf/lib/alerts';
export default class RequestDrawModal extends Controller {
  static targets = [
    'addressSearching',
    'form',
    'selectedDeal',
    'requestDraw',
    'cancelButton',
  ];
  selectedDeal;

  initialize() {
    this.initiateClearSearch();
    this.initiateForm();
    this.initiateSearch();
    this.initiateDealList();
    this.initiateRequestDraw();
  }

  initiateClearSearch = () => {
    this.clearSearch = document.getElementById('clear-request-draw-searching');
    this.clearSearch.addEventListener('click', () => {
      this.addressSearchingTarget.value = '';
      this.handleSearch();
    });
  };

  initiateForm = () => {
    this.formTarget.addEventListener('submit', e => {
      e.preventDefault();
    });
  };

  initiateSearch = () => {
    this.debouncedHandleSearch = debounce(this.handleSearch, 300);
    this.addressSearchingTarget.addEventListener(
      'keyup',
      this.debouncedHandleSearch,
    );
  };

  initiateDealList = () => {
    this.selectedDealTargets.forEach(item => {
      item.addEventListener('click', () => {
        this.selectedDeal = item.dataset.id;
        this.requestDrawTarget.disabled = false;
        this.markDealInUI();
      });
    });
  };

  markDealInUI = () => {
    this.selectedDealTargets.forEach(item => {
      if (item.dataset.id === this.selectedDeal) {
        item.classList.add('selected');
      } else {
        item.classList.remove('selected');
      }
    });
  };

  handleSearch = () => {
    let url = this.addressSearchingTarget.dataset.url;
    url += `?request_draw_searching=${this.addressSearchingTarget.value}`;
    const params = new URLSearchParams(document.location.search);
    params.forEach((value, param) => {
      if (param === 'type') return;
      url = `${url}&${param}=${value}`;
    });

    this.clearSelectedDeal();

    $.ajax({
      url,
      dataType: 'script',
    }).fail(handleAJAXError);
  };

  initiateRequestDraw = () => {
    this.requestDrawTarget.addEventListener('click', () => {
      this.requestDraw();
    });
  };

  requestDraw = () => {
    const deal = this.selectedDealTargets.find(
      item => item.dataset.id === this.selectedDeal,
    );
    if (!deal) {
      return;
    }
    let url = window.gon.requestDrawLink;
    url += `&subject=${deal.dataset.address}&property_state=${deal.dataset.state}&deal_id=${deal.dataset.id}`;
    window.open(url, '_blank').focus();
    this.clearSelectedDeal();
    this.addressSearchingTarget.value = '';
    this.handleSearch();
    this.cancelButtonTarget.click();
  };

  clearSelectedDeal = () => {
    this.selectedDeal = null;
    this.markDealInUI();
    this.requestDrawTarget.disabled = true;
  };
}
